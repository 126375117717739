import { ChangeEvent, useMemo, useState } from "react";
import { SubmitErrorHandler, SubmitHandler, useForm } from "react-hook-form";
import { useRouter } from "next/router";
import { getFormattedPhoneNumber } from "teachers-room/src/utils/string";
import * as yup from "yup";
import styled from "@emotion/styled";
import { yupResolver } from "@hookform/resolvers/yup";

import { Button } from "@shared/lib/components/common/atom/button";
import { FileUploadButton } from "@shared/lib/components/common/atom/button/file-upload-button";
import { CheckBox } from "@shared/lib/components/common/atom/check-box";
import { MultiSelect } from "@shared/lib/components/common/atom/select/multi-select";
import { TextField } from "@shared/lib/components/common/atom/text-feild";
import { MultiLineTextField } from "@shared/lib/components/common/atom/text-feild/multi-line-text-field";
import {
  ALERT_MODAL_TYPE,
  AlertModal,
  AlertModalType,
} from "@shared/lib/components/common/molecules/modal/alert-modal";
import { IMAGES_ACCEPT } from "@shared/lib/constants/file";
import { BUTTON_TYPE } from "@shared/lib/styles/theme";
import { MAX_WIDTH } from "@/constants/style/layout";
import { useAccessToken } from "@/hooks/auth/use-access-token";
import { useProfile } from "@/hooks/auth/use-profile";

import { useGenreControllerGenres } from "@shared/generated/api/fn/kac/genre/genre";
import { KacApiCommonErrorDto } from "@shared/lib/types/api";
import { useInstructorControllerApplyForInstructor } from "@shared/generated/api/fn/kac/instructor/instructor";
import { fileControllerGetSignedUrl } from "@shared/generated/api/fn/kac/file/file";
import { FileControllerGetSignedUrlParams } from "@shared/generated/api/model";
import { uploadFileToGCP } from "@/components/common/features/cloud-storage/upload-file";
import { useTranslation } from "react-i18next";

const ARTIST_NAME_MAX_LENGTH = 30;
const INTRODUCE_WITH_CAREER_MAX_LENGTH = 5000;

interface ApplyForm {
  artistName: string;
  genreIds: string[];
  summary: string;
  description: string;
  experience: string;
  isPrivateAgree: boolean;
}

export const TeacherApplyTemplate = () => {
  const { t } = useTranslation();
  const { data: profile } = useProfile();
  const { accessToken } = useAccessToken();
  const router = useRouter();
  const [profileImageFile, setProfileImageFile] = useState<{
    fileUrl?: string;
    filePath?: string;
  }>();

  const [isAlertModalOpen, setIsAlertModalOpen] = useState(false);

  const [alertModalTexts, setAlertModalTexts] = useState<{
    type: AlertModalType;
    title?: string;
    subTitle?: string;
  }>({
    type: ALERT_MODAL_TYPE.ERROR,
    title: "",
    subTitle: "",
  });

  const {
    data: teacherClassCategory,
    isLoading: isLoadingGetTeacherClassCategory,
  } = useGenreControllerGenres({
    request: {
      accessToken,
    },
  });

  // console.log("here genres", teacherClassCategory);

  const teacherClassCategoryOptions = useMemo(
    () =>
      teacherClassCategory?.map((category) => ({
        label: category.name || "",
        value: String(category.id) || "",
      })),
    [teacherClassCategory],
  );

  const insertImage = (fileName: string) => {
    const baseUrl = process.env.NEXT_PUBLIC_STORAGE_HOST;
    const fileUrl = `${baseUrl}/users/image/${fileName}`;
    setProfileImageFile((prev) => ({ ...prev, fileUrl }));
  };

  const onChangeProfileImgFile = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;
    if (file && accessToken) {
      try {
        const params: FileControllerGetSignedUrlParams = {
          scope: "public",
          originalFilename: file.name,
          resourceType: "users",
          fileType: "image",
        };
        // 1. 백엔드에서 presigned URL 요청
        const { url, filePath } = await fileControllerGetSignedUrl(params, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        console.log(url, filePath);

        if (filePath) setProfileImageFile((prev) => ({ ...prev, filePath }));
        // 2. presigned URL을 사용하여 GCP에 이미지 업로드
        const status = await uploadFileToGCP(url as string, file);

        const pathArr = filePath.split("/");
        const fileName = pathArr[pathArr.length - 1];
        if (status === 200) insertImage(fileName);
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    }
  };

  const teacherApplyMutation = useInstructorControllerApplyForInstructor({
    request: {
      accessToken,
    },
    mutation: {
      onSuccess: () => {
        setAlertModalTexts({
          type: ALERT_MODAL_TYPE.SUCCESS,
          title: t("teacher_application_complete"),
          subTitle: t("dedicated_manager_will_contact_you_soon"),
        });
        setIsAlertModalOpen(true);
      },
      onError: (error) => {
        const { errorCode, message } = error.response
          ?.data as KacApiCommonErrorDto;
        console.log(errorCode, message);
        if (errorCode === 100006) {
          setAlertModalTexts({
            type: ALERT_MODAL_TYPE.ERROR,
            title: t("already_applied_as_teacher"),
            subTitle: t("dedicated_manager_will_contact_you_soon"),
          });
          setIsAlertModalOpen(true);
        } else {
          setAlertModalTexts({
            type: ALERT_MODAL_TYPE.ERROR,
            title: t("failed_to_save_data"),
          });
          setIsAlertModalOpen(true);
        }
      },
    },
  });

  const schema = yup.object({
    artistName: yup.string().required(t("please_enter_teacher_name")),
    genreIds: yup
      .array(yup.string().required())
      .min(1, t("please_select_lecture_genre"))
      .required(t("please_select_lecture_genre_multiple_selection")),
    summary: yup
      .string()
      .required(t("please_enter_teacher_one_line_statement")),
    description: yup.string().required(t("please_enter_teacher_introduction")),
    experience: yup.string().required(t("please_enter_lecture_experience")),
    isPrivateAgree: yup.boolean().required().isTrue(),
  });

  const {
    handleSubmit,
    watch,
    setValue,
    getValues,
    setError,
    register,
    trigger,
    clearErrors,
    formState: { errors, isValid },
  } = useForm<ApplyForm>({
    resolver: yupResolver<ApplyForm>(schema),
    mode: "onChange",
  });

  const artistName = watch("artistName");
  const summary = watch("summary");
  const genreIds = watch("genreIds");
  const description = watch("description");
  const experience = watch("experience");
  const isPrivateAgree = watch("isPrivateAgree");

  const onChangeGenreIds = async (genreIds: string[]) => {
    console.log(genreIds);
    setValue("genreIds", genreIds);
    await trigger("genreIds");
  };

  const onChangeIsPrivateAgree = async (isAgree: boolean) => {
    setValue("isPrivateAgree", isAgree);
    await trigger("isPrivateAgree");
  };

  const onSubmit: SubmitHandler<ApplyForm> = async (data) => {
    const numberIdsArr = data.genreIds.map((id) => Number(id));
    teacherApplyMutation.mutate({
      data: {
        artistName: data.artistName,
        profileImage: profileImageFile?.filePath,
        genreIds: numberIdsArr,
        username: profile?.name || "",
        summary: data.summary,
        description: data.description,
        experience: data.experience,
      },
    });
  };

  const onError: SubmitErrorHandler<ApplyForm> = async (error) => {
    console.error(error);
  };

  const onAlertModalClose = () => {
    if (!teacherApplyMutation.isError && teacherApplyMutation.isSuccess) {
      router.push("/");
    }

    setIsAlertModalOpen(false);
  };

  return (
    <TeacherApplyTemplateContainer>
      <TeacherApplyTemplateInnerContainer>
        <TeacherApplyCardContainer>
          <TeacherApplyTitleContainer>
            {t("apply_teacher")}
          </TeacherApplyTitleContainer>
          <TeacherProfileImageContainer>
            <TeacherProfileImage>
              <img
                src={
                  profileImageFile?.fileUrl
                    ? profileImageFile?.fileUrl
                    : "/images/avatar86.svg"
                }
                alt={t("teacher_profile_image")}
              />
            </TeacherProfileImage>
            <FileUploadButton
              accept={IMAGES_ACCEPT.toString()}
              onChange={onChangeProfileImgFile}
            >
              {t("upload_image")}
            </FileUploadButton>
          </TeacherProfileImageContainer>

          <form onSubmit={handleSubmit(onSubmit, onError)}>
            <TeacherInfoContainer>
              <TeacherInfo>
                <TeacherInfoTitle>{t("basic_information")}</TeacherInfoTitle>
                <TeacherApplyFormInputContainer>
                  <TeacherApplyFormTextField
                    type="text"
                    label={t("name_real_name")}
                    value={profile?.name || ""}
                    readOnly
                    isRequired
                  />
                  <TeacherApplyFormTextField
                    type="text"
                    label={t("email")}
                    value={profile?.email}
                    readOnly
                    isRequired
                  />
                  <TeacherApplyFormTextField
                    type="text"
                    label={t("phone_number")}
                    value={getFormattedPhoneNumber(profile?.phoneNumber || "")}
                    readOnly
                    isRequired
                  />
                </TeacherApplyFormInputContainer>
              </TeacherInfo>
              <TeacherInfo>
                <TeacherInfoTitle>{t("teacher_information")}</TeacherInfoTitle>
                <TeacherApplyFormInputContainer>
                  <TeacherApplyFormTextField
                    type="text"
                    label={t("teacher_name")}
                    isRequired
                    isLengthLabel
                    maxLength={ARTIST_NAME_MAX_LENGTH}
                    {...register("artistName")}
                    isError={!!errors.artistName}
                    value={artistName}
                    placeholder={t("please_enter_teacher_name")}
                  />
                </TeacherApplyFormInputContainer>
                <TeacherApplyFormInputContainer>
                  <TeacherApplyFormTextField
                    type="text"
                    label={t("teacher_one_line_description")}
                    isRequired
                    isLengthLabel
                    maxLength={ARTIST_NAME_MAX_LENGTH}
                    {...register("summary")}
                    isError={!!errors.summary}
                    value={summary}
                    placeholder={t("enter_teacher_one_line_description")}
                  />
                </TeacherApplyFormInputContainer>

                <TeacherApplyFormMultiSelect
                  label={t("lecture_genre")}
                  isRequired
                  isError={!!errors.genreIds}
                  message={errors.genreIds?.message}
                  placeholder={t(
                    "please_select_lecture_genre_multiple_selection",
                  )}
                  options={teacherClassCategoryOptions || []}
                  onChange={onChangeGenreIds}
                  value={genreIds}
                />

                <TeacherApplyFormMultiTextField
                  label={t("teacher_introduction")}
                  isRequired
                  isLengthLabel
                  maxLength={INTRODUCE_WITH_CAREER_MAX_LENGTH}
                  {...register("description")}
                  value={description}
                  isError={!!errors.description}
                  message={errors.description?.message}
                  placeholder={t("introduce_teacher_main_career")}
                />

                <TeacherApplyFormMultiTextField
                  label={t("lecture_experience")}
                  isRequired
                  isLengthLabel
                  maxLength={INTRODUCE_WITH_CAREER_MAX_LENGTH}
                  {...register("experience")}
                  value={experience}
                  isError={!!errors.experience}
                  message={errors.experience?.message}
                  placeholder={t("introduce_lecture_experience_in_detail").replaceAll("\\n", "\n")}
                />
              </TeacherInfo>
            </TeacherInfoContainer>

            <AgreeContainer>
              <AgreeCheckBoxContainer>
                <CheckBox
                  id={"private-checkbox"}
                  isError={!!errors.isPrivateAgree}
                  onChange={() => onChangeIsPrivateAgree(!isPrivateAgree)}
                  checked={isPrivateAgree}
                />
                <span onClick={() => onChangeIsPrivateAgree(!isPrivateAgree)}>
                  {t("agree_to_privacy_policy_required")}
                </span>
              </AgreeCheckBoxContainer>

              <AgreeDivider />

              <AgreeDescription>
                <span>{t("teacher_application_personal_info_agreement").replaceAll("\\n", "\n")}</span>
              </AgreeDescription>
            </AgreeContainer>

            <ApplyButtonContainer>
              <ApplyButton
                styleType={BUTTON_TYPE.FILL}
                type={"submit"}
                disabled={
                  !isValid ||
                  teacherApplyMutation.isPending ||
                  // fileUploadMutation.isPending ||
                  isLoadingGetTeacherClassCategory
                }
              >
                {t("apply_as_teacher")}
              </ApplyButton>
            </ApplyButtonContainer>
          </form>

          {isAlertModalOpen && (
            <AlertModal
              {...alertModalTexts}
              isOpen={isAlertModalOpen}
              onClose={onAlertModalClose}
            />
          )}
        </TeacherApplyCardContainer>
      </TeacherApplyTemplateInnerContainer>
    </TeacherApplyTemplateContainer>
  );
};

const TeacherApplyTemplateContainer = styled.div`
  width: 100%;
  height: 100%;
  min-height: 50vh;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const TeacherApplyTemplateInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 96px 24px;
  width: 100%;
  max-width: ${MAX_WIDTH.INNER_CONTAINER.PC}px;

  ${({ theme }) => theme.media.mobile} {
    padding: 96px 16px;
  }
`;

const TeacherApplyTitleContainer = styled.div`
  ${({ theme }) => theme.typography.h1.b};
  color: ${({ theme }) => theme.colors.text.high.black};

  text-align: center;
`;

const TeacherApplyCardContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 520px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 48px;

  form {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
`;

const TeacherProfileImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

const TeacherProfileImage = styled.div`
  width: 86px;
  height: 86px;
  border-radius: 50%;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const TeacherInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 32px;
`;

const TeacherInfo = styled.div`
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;

  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.gray.gray80};
`;

const TeacherInfoTitle = styled.div`
  ${({ theme }) => theme.typography.h2.b};
  color: ${({ theme }) => theme.colors.text.high.black};
`;

const TeacherApplyFormInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;

const TeacherApplyFormTextField = styled(TextField)`
  gap: 4px;
  flex: 1 0 0;
`;

const TeacherApplyFormMultiTextField = styled(MultiLineTextField)`
  flex: 1 0 0;

  &::placeholder {
    white-space: pre-line;
  }
`;

const TeacherApplyFormMultiSelect = styled(MultiSelect)`
  flex: 1 0 0;
`;

const AgreeContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  justify-content: center;
`;

const AgreeCheckBoxContainer = styled.div`
  margin-top: 32px;

  width: 100%;
  display: flex;
  align-items: center;
  gap: 4px;

  span {
    ${({ theme }) => theme.typography.body2.m};
    color: ${({ theme }) => theme.colors.text.high.black};
  }
`;

const AgreeDivider = styled.div`
  width: 100%;
  height: 1px;
  background: #e6e6e6;
`;

const AgreeDescription = styled.div`
  padding-left: 28px;
  span {
    white-space: pre-line;
    ${({ theme }) => theme.typography.body2.r};
    color: ${({ theme }) => theme.colors.text.medium.black};
  }
`;

const ApplyButtonContainer = styled.div`
  margin-top: 72px;
`;

const ApplyButton = styled(Button)`
  width: 100%;

  &.btn-fill {
    border-radius: 4px;
  }
`;
